<template lang="pug">
  #terms-of-service.step-component
    h2 Kit User Agreement
    .last-updated Last updated February 6, 2024

    .tos-text-container
      .tos-text
        template(v-for="section in contents")
          h2(v-if="section.header" :id="section.id" :class="section.headerClass" v-html="section.header")
          p(v-for="paragraph in section.desc" v-html="paragraph")

    .form-field.checkbox
      input(type="checkbox" @change="scrollTextTo($event, 'terms-of-use', 'informed-consent')"  v-model="informedConsent" :value="informedConsent" id="informedConsent" name="informed-consent")
      label(for="informedConsent") I agree to Informed Consent for Kit Diagnostic Tests and Related Services

    .form-field.checkbox
      input(type="checkbox" @change="scrollTextTo($event, null, 'terms-of-use')"  v-model="termsOfUse" :value="termsOfUse" id="termsOfUse" name="terms-of-use")
      label(for="termsOfUse") I agree to Terms of Use

    .form-field.checkbox
      input(type="checkbox" v-model="privacyPolicy" :value="privacyPolicy" id="privacyPolicy" name="privacy-policy")
      label(for="privacyPolicy") I agree to Privacy Policy

    button.button(:disabled="!hasAgreed" @click="goNext()") Agree
</template>

<script>
const contents = [
  {
    headerClass: 'major-section',
    id: 'general-text',
    desc: [
      'Welcome to the Kit User Agreement (“User Agreement”) between The Kit Company, Inc., and its affiliates and subsidiaries (referred to as “Kit”, “we”, “us”, or “our”), and you  (referred to as “you” or “your”). This User Agreement applies to the use of Kit’s mobile application (“Secure App”) known as the “Kit Platform” and the use of the at home sample collection kit and lab processing services (collectively, the “Kit Diagnostic Tests”), available only within the United States. The Kit Platform and Kit Diagnostic Tests are referred collectively as Kit’s “Services.” This User Agreement incorporates an Informed Consent for Diagnostic Tests and Related Services (“Informed Consent”), our Terms of Use and <a href="https://ro.co/privacy-policy/">Privacy Policy</a>. By agreeing to this User Agreement, you represent that you have read and understood the User Agreement and agree to be bound by the User Agreement.',
      'Kit may, from time to time, change, modify or supplement this User Agreement. Changes to this User Agreement are effective as of the date the changes are published. By continuing to use the Kit Platform and Kit Diagnostic Tests and related services, you agree to comply with, and be bound by, the modified User Agreement.',
      'Please take a minute to ensure that you understand the Informed Consent, our Terms of Use, and our <a href="https://ro.co/privacy-policy/">Privacy Policy</a>, all of which are applicable to your use of the Kit Platform and the Services. ',
      '<strong>ARBITRATION NOTICE: THIS USER AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS, AS DETAILED IN THE “DISPUTES; ARBITRATION AGREEMENT; CLASS ACTION WAIVER” SECTION IN THE TERMS OF USE. THESE TERMS AFFECT YOUR LEGAL RIGHTS. PLEASE READ IT CAREFULLY.</strong>',
      'You understand that by checking the “agree” box for this User Agreement and/or any other forms presented to you through the Services, you are agreeing to the entirety of this User Agreement and that such action constitutes a legal signature.',
      '<strong><u>BY CLICKING ON “I AGREE”, YOU AGREE AND CONSENT TO BE BOUND BY THIS USER AGREEMENT, INCLUDING THE INFORMED CONSENT, TERMS OF USE, AND <a href="https://ro.co/privacy-policy/">PRIVACY POLICY</a>. IF YOU DO NOT UNDERSTAND OR AGREE TO BE BOUND BY THIS AGREEMENT, DO NOT ACCESS OR USE OUR SERVICES, INCLUDING THE KIT PLATFORM OR THE KIT DIAGNOSTIC TESTS.</u></strong>',
    ],
  },
  {
    header: 'I. INFORMED CONSENT FOR KIT DIAGNOSTIC TESTS AND RELATED SERVICES',
    headerClass: 'major-section',
    id: 'informed-consent',
  },
  {
    header: 'Sample Collection and Uses',
    headerClass: 'sub-section ul-text',
    id: 'sample-collection',
    desc: [
      'In order to use the Kit Diagnostic Tests, you will collect a diagnostic sample(s) (“Sample”) using the Kit Diagnostic Test to submit to our CLIA certified laboratory or a third-party CLIA certified laboratory (our “Lab”). The Lab will process your Sample(s) and provide testing results (“Results”) as requested by you, or your healthcare provider.',
      'In order for the Lab to process your Samples, you must provide correct and accurate information, as well as follow the instructions provided by Kit. Failure to provide the required information or to follow instructions may result in your Sample not being tested or may lead to inaccurate Results.',
      'The Results will be made available to you, or as applicable, your healthcare provider. If the Lab determines that a Sample is not suitable for testing due to the contents of the Sample, the methodology of the Sample collection, or if Kit believes the Sample has been submitted in violation of this User Agreement, Kit reserves the right to withhold the Results of your Sample from you and/or your healthcare provider.',
    ],
  },
  {
    header: 'Testing',
    headerClass: 'sub-section ul-text',
    id: 'testing',
    desc: [
      'Kit has implemented rigorous safeguards designed to ensure that the Kit Diagnostic Tests provide accurate Results.  By agreeing to this User Agreement, you are indicating that you understand that, as with all lab testing, there is a chance of false positive or false negative results. There are many potential sources of error, including but not limited to, the failure to identify a biomarker, a sample confusion, insufficient sample size, contaminated samples, or technical laboratory errors.',
    ],
  },
  {
    header: 'Risks',
    headerClass: 'sub-section ul-text',
    id: 'risks',
    desc: [
      'In order to utilize Kit’s Diagnostic Tests, you will need to collect the appropriate Samples. Kit’s Diagnostic Tests require the collection of a blood sample, using Kit’s proprietary technology and accompanying detailed instructions. Although the risks associated with blood draw are low, when drawing a blood sample you may be at risk for excessive bleeding, fainting, feeling light-headed, bruising, hematoma (blood accumulating under the skin), or infection (a slight risk any time the skin is broken). If you have a history of excessive bleeding or fainting while having blood work done, Kit advises avoiding any testing that requires self blood collection.',
    ],
  },
  {
    header: 'Modifications or Changes to the Kit Platform and the Kit Diagnostic Tests',
    headerClass: 'sub-section ul-text',
    desc: [
      'Kit may, from time to time, and in Kit’s sole discretion, add or remove features from the Kit Platform or from the Kit Diagnostic Tests, without notice to you.',
    ],
  },
  {
    header:
      'You represent that you have read and understand this Informed Consent and consent to the following:',
    headerClass: 'sub-section ul-text',
    desc: [
      '<strong>1. Consent to Process and Analyze Your Samples:</strong> You consent to your use of the Kit Diagnostic Tests to collect your Samples. If you return your Kit Diagnostic Tests to Kit with you Samples, you permit Kit to process and analyze your Samples at our Lab.',
      '<strong>2. Consent for Kit to Share Your Information with MJB Lab Services, Inc.</strong> The services provided through the Kit Platform are only available with a physician order. Kit does not provide physician or healthcare services, or lab authorization services. By agreeing to this Informed Consent, you consent to sharing your information with MJB Lab Services, Inc., an independent provider service, not affiliated with Kit.',
      '<strong>3. Consent for Kit to Share your Results with Your Healthcare Provider. </strong> You consent to Kit sharing your Results with you or your healthcare provider. You understand that you are not entering into a doctor-patient relationship with Kit, and that any questions or required follow up shall be your responsibility to arrange with your own healthcare provider.',
      '<strong>4. Consent to Recording.</strong> For the purposes of and in order to provide you with certain Services, Kit may audio and video record your interactions with the Secure App. You consent to Kit recording your interaction. We will take steps to comply with applicable laws related to any such recordings (such as, as applicable, California’s Invasion of Privacy Act and similar laws in other jurisdictions).',
      'You understand that Kit Diagnostic Tests and the Results of the Kit Diagnostic Tests are for information and educational use only.  Kit does not practice medicine and our services are not intended to replace medical advice or treatment. Kit is not responsible for any professional advice rendered by a healthcare professional or physician based on the Results of your Sample(s). Any information and content provided are for informational purposes only and are not a substitute for medical advice, diagnosis, or treatment. <strong>By accepting this Informed Consent, you acknowledge that the Results should not be used for diagnostic purposes or treatment (except by a healthcare provider), and are not a substitute for medical advice.</strong>',
      'If you want to withdraw your consent to this Informed Consent, please send an email to: <a href="mailto:kit.support@ro.co">kit.support@ro.co</a>. Even if you withdraw your consent, we may need to retain your personal information, Sample(s) or Results to meet our legal obligations or where we have a reasonable belief that we have a legitimate and legal reason to do so.',
    ],
  },
  {
    header: 'II. TERMS OF USE',
    headerClass: 'major-section',
    id: 'terms-of-use',
    desc: [
      '<strong>ARBITRATION NOTICE: THESE TERM OF USE ARE SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS, AS DETAILED IN THE “DISPUTES; ARBITRATION AGREEMENT; CLASS ACTION WAIVER” SECTION BELOW. THESE TERMS AFFECT YOUR LEGAL RIGHTS. PLEASE READ IT CAREFULLY.</strong>',
      '<strong><u>By clicking “I agree,” you agree to be bound by these Terms of Use, which are incorporated into the User Agreement.</u></strong>',
    ],
  },
  {
    header: 'Eligibility and Access to and use of the Services',
    headerClass: 'sub-section ul-text',
    desc: [
      'By agreeing to the Terms of Use, you confirm that you are at least eighteen (18) years of age, currently reside in the United States, and are initiating the testing on behalf of yourself. By initiating testing through the Services, you represent and warrant that all of the information you have provided is accurate, and that you are requesting that all Results are reported directly to you. In addition, you agree to comply with the User Agreement, Kit policies or notices as provided or referenced herein, and all applicable laws. Kit reserves the right to block or restrict access to the Services if you are in breach of this Terms of Use, to protect our Services, or to protect patients.',
      'We hereby grant to you a limited, non-exclusive, nontransferable right to access the Kit Platform and use the Kit Diagnostic Tests and the Kit Platform solely for your personal non-commercial use and only as permitted under the Terms of Use and any separate agreements you may have entered into with us. We reserve the right, in our sole discretion, to deny or suspend use of the Kit Platform or access to the Kit Diagnostic Tests for any reason.',
      'You are required to provide us certain pieces of information in order to access our Secure App, including your name, date of birth and email address (collectively, “Account Information”). You agree that the Account Information that you provide to us will be true, accurate, current, and complete. You may not input Account Information for another individual.',
    ],
  },
  {
    header: 'No Healthcare Services',
    headerClass: 'sub-section ul-text',
    desc: [
      'Kit provides the Kit Platform and the Kit Diagnostic Tests for informational purposes only. Information provided through the Kit Platform is not medical or healthcare advice. Always seek the advice of your healthcare provider with any questions regarding the interpretation of your laboratory results or a medical condition. If you are experiencing a healthcare emergency, immediately call 9-1-1 or seek the advice from emergency healthcare providers or providers. Do not delay seeking or obtaining medical advice based on information you receive through the Kit Platform.',
      'Kit and/or Lab are not responsible or liable for decisions made by your healthcare provider based on the Results derived from the Kit Diagnostic Test.',
    ],
  },
  {
    header: 'Physician Order',
    headerClass: 'sub-section ul-text',
    desc: [
      'The services provided through the Kit Platform and Kit Diagnostic Tests are only available with a physician order. Kit does not provide physician or healthcare services, or lab prior authorization services. All tests are reviewed by a physician licensed in your state of residence through MJB Lab Services, Inc. an independent provider service not affiliated with Kit.',
    ],
  },
  {
    header: 'Geographical Restrictions',
    headerClass: 'sub-section ul-text',
    desc: [
      'Currently, the Kit Platform and Kit Diagnostic Tests are not available in New York, Maryland, Rhode Island, and Pennsylvania. Kit retains the right to amend the locations where the services are available at any time and for any reason. Kit makes no representations that all products, services and/or material described on the Kit Platform or Secure App or the Kit Diagnostic Tests and related services are appropriate or available for use in locations outside the United States or all states and territories within the United States.',
    ],
  },
  {
    header: 'Reporting',
    headerClass: 'sub-section ul-text',
    desc: [
      'You acknowledge and accept that the Lab, your healthcare provider, or any healthcare provider who reviews your results may be required to or compelled by law or regulation to report your Results to the local, state, or federal health authorities. For example, many states require the testing laboratory and physician to report test results for certain communicable diseases, such sexually transmitted diseases to local or state health departments.',
    ],
  },
  {
    header: 'Prohibited Use and Restrictions',
    headerClass: 'sub-section ul-text',
    desc: [
      'By using the Services, you acknowledge and agree that you will not and will not attempt to: (i) impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity; (ii) use the Kit Platform to violate any local, state, national or international law; (iii) reverse engineer, disassemble, decompile, or translate any software or other components of the Kit Platform or the Kit Diagnostic Tests; (iv) distribute viruses or other harmful computer code through the Kit Platform; or (v) otherwise use the Kit Platform in any manner that exceeds the scope of use granted by these Terms of Use.',
    ],
  },
  {
    header: 'Disclaimer of Warranties',
    headerClass: 'sub-section ul-text',
    desc: [
      'YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. KIT EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, NON-INFRINGEMENT, TITLE, OPERABILITY, CONDITION, QUIET ENJOYMENT, VALUE, ACCURACY OF DATA AND SYSTEM INTEGRATION.',
    ],
  },
  {
    header: 'Limitation of Liability',
    headerClass: 'sub-section ul-text',
    desc: [
      'YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL KIT, THE LAB, OR THEIR RESPECTIVE  SHAREHOLDERS, OFFICERS, EMPLOYEES, DIRECTORS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), WARRANTY, STATUTE OR OTHERWISE.',
      'TO THE EXTENT THAT WE MAY NOT, AS A MATTER OF APPLICABLE LAW, DISCLAIM ANY IMPLIED WARRANTY OR LIMIT ITS LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY AND THE EXTENT OF OUR LIABILITY WILL BE THE MINIMUM PERMITTED UNDER SUCH APPLICABLE LAW.',
    ],
  },
  {
    header: 'Indemnification',
    headerClass: 'sub-section ul-text',
    desc: [
      'You agree to indemnify, defend and hold harmless Kit and its respective shareholders, officers, directors, employees, agents, subsidiaries, affiliates, licensors, and suppliers, harmless from and against any claims, actions, demands, liabilities and settlements, including, without limitation, reasonable legal and accounting fees (“Claims”), resulting from, or alleged to result from, your violation of these Terms of Use.',
    ],
  },
  {
    header: 'Ownership',
    headerClass: 'sub-section ul-text',
    desc: [
      'The Services and its entire contents, features and functionality (including, but not limited to, all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by Kit, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. These Terms of Use permit you to use the Services for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Services except as generally and ordinarily permitted through the Services according to these Terms of Use. You must not access or use for any commercial purposes any part of the Services or any services or materials available through the Services.',
    ],
  },
  {
    header: 'Trademarks',
    headerClass: 'sub-section ul-text',
    desc: [
      'Names, logos, and other materials displayed on the Services may constitute trademarks, trade names, service marks or logos ("Marks") of Kit or other entities. You are not authorized to use any such Marks without the express written permission from Kit. Ownership of all such Marks and the goodwill associated therewith remains with us or those other entities.',
    ],
  },
  {
    header: 'Privacy and Communications',
    headerClass: 'sub-section ul-text',
    desc: [
      'We take reasonable and appropriate steps designed to ensure that your personal information and the information you provide to kit for receiving and processing your Kit Diagnostic Tests are secure. Information relating to Samples and Results are stored on secure, encrypted servers maintained by Kit. Information regarding our use of your personal information, including your health-related information is provided in our <a href="https://ro.co/privacy-policy/">Privacy Policy</a>.',
      'As part of providing you with our Services, we may need to provide you with certain communications such as reminders, service announcements, privacy policies, administrative messages and other communications about the Services (“<strong>Communications</strong>”). These Communications are considered part of the Services and your account.',
      'By agreeing to these Terms of Use, you agree that we may send to you Communications through electronic means including, but not limited to, (1) by email, using the address that you provided to us during registration, (2) short messaging service (“<strong>SMS</strong>”) text message to the mobile number you provided us during registration (“<strong>SMS Messages</strong>”), or (3) push notifications on your tablet or mobile device through the Secure App. The delivery of any Communications from us is effective when sent by us, regardless of whether you read the Communication. You can withdraw your consent to receive Communications by contacting <a href="mailto:kit.support@ro.co">kit.support@ro.co</a>.',
    ],
  },
  {
    header: 'SMS Terms and Conditions',
    headerClass: 'sub-section ul-text',
    desc: [
      'The number of SMS Messages we send as part of our Communications will depend on the frequency of your use of the Services. You can stop receiving SMS Messages at any time by texting "<strong>STOP</strong>" to 91892. If you send the message "<strong>STOP</strong>" to us, we will send you a reply message to confirm that you have been unsubscribed from SMS Messages. After this, you will no longer receive SMS Messages from us. If you text "<strong>HELP</strong>" to us, we will reply with instructions on how to receive SMS Messages as well as how to unsubscribe from SMS Messages.',
      'Message and data rates may apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.',
    ],
  },
  {
    header: 'Participating Carriers',
    headerClass: 'sub-section ul-text',
    desc: [
      'AT&T, Verizon Wireless, Sprint, T-Mobile, U.S. Cellular, Boost Mobile, MetroPCS, Virgin Mobile, Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central, IL (ECIT), Cellular One of Northeast Pennsylvania, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless).',
      'T-Mobile is not liable for delayed or undelivered messages.',
      'Message and data rates may apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.',
    ],
  },
  {
    header: 'Disputes; Arbitration Agreement; Class Action Waiver',
    headerClass: 'sub-section ul-text',
    desc: [
      'This Agreement shall be governed and construed in accordance with laws of the State of Delaware, without giving effect to the principles of conflict of laws.',
      '<strong>By agreeing to these Terms of Use, you agree that any dispute arising under or relating in any way to these Terms of Use will be resolved exclusively by final and binding arbitration in New York, New York under the rules of the American Arbitration Association (AAA).  You further agree to not participate in a class action or class-wide arbitration for any Claims covered by this section. You are waiving the ability to participate as a class representative or member in any class or collective claim you may have against us, including any right to class or collective arbitration or any consolidation of individual arbitration.</strong>',
      'Notwithstanding the foregoing, either party may bring a claim related to intellectual property rights, or seek temporary and preliminary specific performance and injunctive relief, in any court of competent jurisdiction. The parties agree that the courts located in New York, New York shall have exclusive personal jurisdiction, subject matter jurisdiction, and venue for any such claim.',
    ],
  },
  {
    header: 'Severability',
    headerClass: 'sub-section ul-text',
    desc: [
      'All parts of these Terms of Use apply to the maximum extent permitted by law. Kit and you both agree that if we cannot enforce a part of this contract as written, then that part will be replaced with terms that most closely match the intent of the part we cannot enforce, to the extent permitted by law. The invalidity of part of these Terms of Use will not affect the validity and enforceability of the remaining provisions.',
    ],
  },
  {
    header: 'Modifications to this Terms of Use',
    headerClass: 'sub-section',
    desc: [
      'Kit may, from time to time, change, modify or supplement these Terms of Use. Changes to these Terms of Use are effective as of the date the changes are published. By continuing to use the Kit Platform and Kit Diagnostic Tests and related services, you agree to comply with, and be bound by, the modified Terms of Use.',
    ],
  },
  {
    header: 'No Agency Relationship ',
    headerClass: 'sub-section',
    desc: [
      'Neither these Terms of Use or any part of our Services create any partnership, joint venture, employment, or other agency relationship between us and you. You may not enter into any contract on our behalf or bind us in any way.',
    ],
  },
  {
    header: 'Assignment',
    headerClass: 'sub-section',
    desc: [
      'You may not assign your rights under these Terms of Use, and any such attempt will be null and void. Kit and its affiliates may, in its individual discretion, transfer, without further consent by or notification to you, all contractual rights and obligations pursuant to these Terms of Use.',
    ],
  },
  {
    header: 'DMCA Policy',
    headerClass: 'sub-section ul-text',
    desc: [
      'The Digital Millennium Copyright Act of 1998 (the "<strong>DMCA</strong>") provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you believe in good faith that materials appearing on the Services infringe your copyright, you (or your agent) may send us a notice requesting that the material be removed, or access to it blocked. In addition, if you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send us a counter-notice. Notices and counter-notices must meet statutory requirements imposed by the DMCA. One place to find more information is the U.S. Copyright Office Website, currently located at https://www.loc.gov/copyright. In accordance with the DMCA, Kit has designated an agent to receive notification of alleged copyright infringement in accordance with the DMCA. Any written Notification of Claimed infringement should comply with Title 17, United States Code, Section 512(c)(3)(A) and should be provided in writing to The Kit Company, Inc., 2325 3rd Street Suite 403, San Francisco, CA 94107.',
      'Please send any questions or report any violations of these Terms of Use to <a href="mailto:kit.support@ro.co">kit.support@ro.co</a>.',
    ],
  },
]

export default {
  props: {},

  data() {
    return {
      contents: contents,
      informedConsent: false,
      privacyPolicy: false,
      termsOfUse: false,
    }
  },

  computed: {
    hasAgreed,
  },

  watch: {},

  methods: {
    goNext,
    scrollTextTo,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function hasAgreed() {
  return this.informedConsent && this.privacyPolicy && this.termsOfUse
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goNext() {
  this.$emit('complete')
}

function scrollTextTo(event, nextID, currentID) {
  if (event.target.checked) {
    if (nextID) {
      document.getElementById(nextID).scrollIntoView({ behavior: 'smooth' })
    }
  } else {
    document.getElementById(currentID).scrollIntoView({ behavior: 'smooth' })
  }
}
</script>
